@font-face {
font-family: '__copernicus_d584e7';
src: url(/_next/static/media/92b4112f73a551ef-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__copernicus_Fallback_d584e7';src: local("Arial");ascent-override: 73.91%;descent-override: 21.74%;line-gap-override: 0.00%;size-adjust: 115.02%
}.__className_d584e7 {font-family: '__copernicus_d584e7', '__copernicus_Fallback_d584e7'
}

@font-face {
font-family: '__copernicusBold_eaa1c0';
src: url(/_next/static/media/cf2461599666a3d4-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__copernicusBold_Fallback_eaa1c0';src: local("Arial");ascent-override: 71.35%;descent-override: 20.99%;line-gap-override: 0.00%;size-adjust: 119.13%
}.__className_eaa1c0 {font-family: '__copernicusBold_eaa1c0', '__copernicusBold_Fallback_eaa1c0'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Noto_Sans_fba9a0';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/cd252053465ccde6-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Noto_Sans_fba9a0';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/a9bb6ffb9beb5cd8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: '__Noto_Sans_fba9a0';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e8971466927ac567-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}
/* greek-ext */
@font-face {
  font-family: '__Noto_Sans_fba9a0';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/d98495f192f000ea-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Noto_Sans_fba9a0';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/0f8cb4c7afa7f209-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Noto_Sans_fba9a0';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9dead954f7453cf5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Noto_Sans_fba9a0';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/4ec1cbda918af0e0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Noto_Sans_fba9a0';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2dcd4f0f2e72cbfd-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Noto_Sans_Fallback_fba9a0';src: local("Arial");ascent-override: 100.54%;descent-override: 27.56%;line-gap-override: 0.00%;size-adjust: 106.33%
}.__className_fba9a0 {font-family: '__Noto_Sans_fba9a0', '__Noto_Sans_Fallback_fba9a0';font-weight: 400;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Noto_Sans_5c1228';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/a43324d6c6208bb0-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Noto_Sans_5c1228';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/63bbd81086276d8f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: '__Noto_Sans_5c1228';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ef0170264e4a2009-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}
/* greek-ext */
@font-face {
  font-family: '__Noto_Sans_5c1228';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/f67dbe2e12f11946-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Noto_Sans_5c1228';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/735f32592cc04d7d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Noto_Sans_5c1228';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/48273f0bfd5b4c0b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Noto_Sans_5c1228';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/990c8077004fe69a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Noto_Sans_5c1228';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/24b4f98024af955d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Noto_Sans_Fallback_5c1228';src: local("Arial");ascent-override: 100.54%;descent-override: 27.56%;line-gap-override: 0.00%;size-adjust: 106.33%
}.__className_5c1228 {font-family: '__Noto_Sans_5c1228', '__Noto_Sans_Fallback_5c1228';font-weight: 700;font-style: normal
}

@font-face {
font-family: '__polaris_72dcf2';
src: url(/_next/static/media/c2d822f64d3fc299-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__polaris_Fallback_72dcf2';src: local("Arial");ascent-override: 73.83%;descent-override: 18.46%;line-gap-override: 6.37%;size-adjust: 108.36%
}.__className_72dcf2 {font-family: '__polaris_72dcf2', '__polaris_Fallback_72dcf2'
}

@font-face {
font-family: '__polarisBold_fa1562';
src: url(/_next/static/media/98da709cc765dd66-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__polarisBold_Fallback_fa1562';src: local("Arial");ascent-override: 71.43%;descent-override: 17.86%;line-gap-override: 6.16%;size-adjust: 112.00%
}.__className_fa1562 {font-family: '__polarisBold_fa1562', '__polarisBold_Fallback_fa1562'
}

